import React, { Fragment } from 'react';
import {
    Box,
    Container,
    Grid,
} from '@material-ui/core';

export const Loading = () => {
    return (
        <>
            <Container maxWidth='xs'>
                <Grid container direction='row' justify='center' alignItems='center'>
                    <Box>
                        <div className='spin-wrapper'>
                            <div className='spinner' />
                        </div>
                    </Box>
                </Grid>
            </Container>
        </>
    );
};
