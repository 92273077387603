import 'react-app-polyfill/stable';
import 'react-app-polyfill/ie11';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
//import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { Loading } from './components/spinners';
import './index.scss';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
// @ts-ignore
import AlertTemplate from 'react-alert-template-basic';
import { AuthSettingsApiModel } from './api/models/AuthSettingsApiModel';

// tslint:disable-next-line: no-var-requires
require('bootstrap');

const App = React.lazy(() => import('./App'));

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');

const rootElement = document.getElementById('root');

const alertOptions = {
    position: positions.BOTTOM_CENTER,
    timeout: 5000,
    offset: '30px',
    transition: transitions.SCALE,
};

const renderApp = () => {
    ReactDOM.hydrate(
        (
            <BrowserRouter basename={baseUrl !== null ? baseUrl : ''}>
                <AlertProvider template={AlertTemplate} {...alertOptions}>
                    <Suspense fallback={<Loading />}>
                        <App />
                    </Suspense>
                </AlertProvider>
            </BrowserRouter>
        ),

        rootElement);
};

fetch('/api/Configuration/B2C')
    .then(response => { return response.json(); })
    .then((data: AuthSettingsApiModel) => {
        localStorage.setItem('authSettings', JSON.stringify(data));
        renderApp();
    });
